<template>
  <Wrapper :appName="appName">

    <v-alert
      text
      color="deep-orange"
      v-show="!hideInitBanner"
      style="margin-top: 10px;"
    >
      Для обеспечения безопасности аккаунта необходимо изменить пароль.
    </v-alert>

    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="error"
      v-show="isBlocked || notValidated"
    >
      Невозможно изменить пароль сейчас. Пожалуйста, обратитесь в техническую поддержку.
    </v-alert>

    <v-alert
      text
      color="error"
      v-show="passwordError"
      style="margin-top: 10px;"
    >
      <b>Пароль не изменён.</b><br />
      <ul>
        <li v-for="(item, index) in reasons" :key="index">{{item}}</li>
      </ul>
      
    </v-alert>

    <v-alert outlined type="success" text v-if="isCompleted" style="margin-top: 10px; margin-bottom: 0px;">
      Пароль успешно изменен. Вы можете войти в аккаунт.
    </v-alert>

    <form
      class="auth__form d-flex flex-column"
      v-on:submit="
        ($event) => {
          $event.preventDefault();
          pefrormChangePassword($event, true);
        }
      "
      @keyup.enter.native="
        ($event) => {
          $event.preventDefault();
          pefrormChangePassword($event, true);
        }
      "
    >

    
    <div class="auth__input auth__two_params rounded d-flex flew-row" v-if="!isBlocked && !isCompleted">
            <input
              v-model="newpassword"
              name="newpassword"
              v-if="!show1"
              placeholder="Новый пароль"
              type="password"
            />
            <input
              v-model="newpassword"
              name="newpassword"
              v-else
              placeholder="Новый пароль"
              type="text"
            />
            <button v-on:click="switchVisibleIcon1" class="button" type="button">
              <v-icon>{{ show1 ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
            </button>
    </div>

    <div class="auth__input auth__two_params rounded d-flex flew-row" v-if="!isBlocked && !isCompleted" style="margin-top: 10px;">
            <input
              v-model="newpassword2"
              name="newpassword2"
              v-if="!show2"
              placeholder="Подтверждение пароля"
              type="password"
            />
            <input
              v-model="newpassword2"
              name="newpassword2"
              v-else
              placeholder="Подтверждение пароля"
              type="text"
            />
            <button v-on:click="switchVisibleIcon2" class="button" type="button">
              <v-icon>{{ show2 ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
            </button>
    </div>

    <v-alert
    text
    color="error"
    v-show="passwordsMatchError && newpassword !== newpassword2"
    style="margin-top: 10px; margin-bottom: 0px;"
  >
    Пароль должен совпадать с подтверждением
  </v-alert>

    <button
      class="auth__button auth__transition mt-5 rounded title font-weight-bold"
      v-on:click="pefrormChangePassword"
      :disabled="isLoginInProcess || !newpassword || (!password && !preAuthPassword)"
      v-show="!isBlocked && !isCompleted"
    >
      Изменить пароль
    </button>

    <button
    class="auth__button auth__transition mt-5 rounded title font-weight-bold"
    v-on:click="goToLogin"
    v-show="isCompleted"
  >
    Перейти ко входу в систему
  </button>
</form>
  </Wrapper>
</template>

<script>
import { endpointUrl } from "../../app.config";
import axios from "axios";
import Wrapper from "./Wrapper.vue";

export default {
  components: {
    Wrapper,
  },
  props: {
    user: Object,
    appName: String,
  },
  name: "ChangePassword",
  data: () => ({
    loginemail: "",
    notValidated: false,
    isBlocked: false,
    passwordError: false,
    reason: "",
    reasons: [],
    preAuthPassword: false,
    newpassword: "",
    newpassword2: "",
    show1: false,
    show2: false,
    isCompleted: false,
    password: "",
    hideInitBanner: false,
    isLoginInProcess: false,
    passwordsMatchError: false
  }),

  computed: {
  },

  created() {
    let self = this;
    if (
      this.$route.params.changePasswordUsername &&
      this.$route.params.changePasswordTmpPassword
    ) {
      self.loginemail = this.$route.params.changePasswordUsername;
      self.password = this.$route.params.changePasswordTmpPassword;
      self.preAuthPassword = true;
    } else {
        this.$router.push("/login");
    }
  },

  methods: {
    goToLogin(e) {
      if (e) e.preventDefault();
      this.$router.push("/login");
    },
    switchVisibleIcon1(e) {
      if (e) e.preventDefault();
      this.show1 = !this.show1;
    },
    switchVisibleIcon2(e) {
      if (e) e.preventDefault();
      this.show2 = !this.show2;
    },
    pefrormChangePassword(e) {
      if (e) e.preventDefault();
      let self = this;

      self.passwordsMatchError = false;

      if(self.newpassword !== self.newpassword2) {
        self.passwordsMatchError = true;
        return;
      }

      if (!self.isLoginInProcess && self.loginemail && self.newpassword && self.newpassword === self.newpassword2) {
        self.notValidated = false;
        self.isLoginInProcess = true;
        self.passwordError = false;
        self.reason = "";
        self.hideInitBanner = true;
        
        axios({
          url: endpointUrl + "changepassword",
          method: "POST",
          data: {
            username: self.loginemail,
            password: self.password,
            new_password: self.newpassword
          },
        }).then((res) => {
          self.isLoginInProcess = false;
          self.newpassword = "";
          self.newpassword2 = "";
          if (res && res.data && res.data.status === "ok") {
            self.isCompleted = true;
          } else if (res && res.data && res.data.status === "Access denied") {
            if (res.data.isBlocked) {
                self.isBlocked = true;
            } else {
                self.notValidated = true;
            }
          } else if (res && res.data && res.data.status === "error") {
            self.passwordError = true;
            self.reason = res.data.reason;
            self.reasons = res.data.reason ? res.data.reason.split('; ') : [];
          }
        });
      }
      
    },
  },
};
</script>
