<template>
  <div class="vars__options auth__container d-flex justify-center">
    <div
      class="auth__wrapper d-flex flex-row"
      :class="wrapperStyles ? wrapperStyles : ''"
    >
      <div
        class="auth__col-4 d-flex flex-column justify-center align-start"
        :class="[authFormStyles ? authFormStyles : '']"
      >
        <img
          v-if="appName && appName === 'Explo-IT'"
          :class="[logoStyles !== '' ? logoStyles : 'mb-10']"
          src="../assets/Explo-IT.svg"
          height="64"
        />
        <h1 v-else-if="appName" class="auth__logo_title mb-3">{{ appName }}</h1>
        <img
          v-else
          :class="[logoStyles !== '' ? logoStyles : 'mb-10']"
          src="../assets/Explo-IT.svg"
          height="64"
        />
        <!-- Расскоментировать для ммк и закомментировать картинку выше -->

        <h3 v-if="title" class="title mb-5">{{ title }}:</h3>
        <slot :class="[slotStyles ? slotStyles : '']"></slot>
      </div>
    </div>
    <Banner></Banner>
  </div>
</template>

<script>
import "../assets/styles/main.css";
import "../assets/styles/register.css";
import { STATIC_TO_SLIDE } from "../plugins/slider.js";
import Banner from "./Banner.vue";

export default {
  components: {
    Banner,
  },
  props: [
    "title",
    "logoStyles",
    "wrapperStyles",
    "authFormStyles",
    "slotStyles",
    "appName",
  ],
  data() {
    return {
      slides: [],
      activeSlide: null,
    };
  },
  beforeMount() {
    this.slides =
      STATIC_TO_SLIDE && STATIC_TO_SLIDE.length ? [...STATIC_TO_SLIDE] : [];
    this.activeSlide = this.slides.length && this.slides[0];

    setInterval(() => {
      let currentIndex = this.slides.findIndex(
        (item) => item === this.activeSlide
      );
      if (!this.slides[currentIndex + 1]) {
        this.activeSlide = this.slides[0];
      } else {
        this.activeSlide = this.slides[currentIndex + 1];
      }
    }, 10000);
  },
};
</script>
