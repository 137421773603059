<template>
  <div class="home">
    <Support :appName="appName" />
  </div>
</template>

<script>
// @ is an alias to /src
import Support from '@/components/Support.vue'

export default {
  props:['appName'],
  name: 'SupportView',
  components: {
    Support
  }
}
</script>
