<template>
  <v-app>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col>
          <router-view v-show="isServiceOk" :user="user" :appName="appName" />
          <AppLoading :isLoading="isServiceloading" />
          <Wrapper
            :appName="appName"
            v-if="isServiceFailed"
            :authFormStyles="'auth__col-6'"
          >
            <v-alert
              class="mt-6"
              v-show="isServiceFailed"
              text
              prominent
              type="error"
              icon="mdi-cloud-alert"
            >
              Система временно недоступна. Пожалуйста, попробуйте выполнить вход
              позднее, или сообщите об ошибке в техническую поддержку.
            </v-alert>
          </Wrapper>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { endpointUrl } from "../app.config";
import AppLoading from "./components/AppLoading";
import axios from "axios";
import Wrapper from "./components/Wrapper.vue";

export default {
  components: {
    AppLoading,
    Wrapper,
  },
  data: (vm) => {
    return {
      appName: "",
      isServiceloading: true,
      isServiceOk: false,
      isServiceFailed: false,
      user: vm.$root.user,
    };
  },
  computed: {
    areWeOnLoginPage() {
      return this.$router.currentRoute.name === "Login" || !this.user.logged;
    },
  },
  created() {
    let self = this;
    let heartbeatPromise = axios({
      url: endpointUrl + "heartbeat",
      method: "GET",
    });

    heartbeatPromise
      .then((res) => {
        if (res && res.data && res.data.status === "ok") {
          self.isServiceloading = false;
          self.isServiceOk = true;
          self.isServiceFailed = false;
          self.appName = res.data.name ? res.data.name : "ExploIT";
        } else {
          throw new Error("no valid data");
        }
      })
      .catch((e) => {
        console.log(e);
        self.isServiceloading = false;
        self.isServiceOk = false;
        self.isServiceFailed = true;
      });
  },
};
</script>

<style scoped>
.v-application {
  background: #3a1c71 !important;
  background: -webkit-linear-gradient(
    to bottom right,
    rgb(241 241 241),
    rgb(225 225 225),
    rgb(5, 38, 63)
  ) !important;
  background: linear-gradient(
    to bottom right,
    rgb(241 241 241),
    rgb(225 225 225),
    rgb(5, 38, 63)
  ) !important;
}
</style>
