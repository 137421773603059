<template>
  <Wrapper
    :appName="appName"
    :logoStyles="'mb-5'"
    :wrapperStyles="'auth__transition'"
    :title="'Восстановление доступа через службу поддержки'"
  >
    <div class="d-flex flex-column justify-center auth__transition">
      <span
        class="text-subtitle-2 mb-2"
        :class="isCompleted ? 'auth__hide_form' : ''"
      >
        Все поля этой формы не обязательны для заполнения, однако от полноты и
        точности заявки будет зависеть скорость и возможность восстановить
        доступ.
      </span>

      <v-alert outlined type="success" text v-if="isCompleted">
        Ваша заявка успешно отправлена в службу поддержки. Мы свяжемся с Вами в
        процессе рассмотрения заявки.
      </v-alert>

      <button
        v-if="isCompleted"
        v-on:click="goToLogin"
        class="auth__button auth__transition rounded"
      >
        Вернуться ко входу
      </button>

      <form
        class="auth__col-4 auth__transition"
        :class="isCompleted ? 'auth__hide_form' : ''"
        v-on:submit="pefrormRestore"
        @keyup.enter.native="pefrormRestore"
        ref="supportform"
      >
        <div class="auth__input auth__two_params mb-3 rounded">
          <input
            v-on:blur="isValidated"
            v-model="userlogin"
            name="userlogin"
            placeholder="Логин"
          />
        </div>
        <div class="auth__input mb-3 rounded">
          <input
            v-on:blur="isValidated"
            v-model="useremail"
            name="useremail"
            placeholder="Почта"
          />
        </div>
        <div class="auth__input mb-3 rounded">
          <input
            v-model="useremailactual"
            v-on:blur="isValidated"
            name="useremailactual"
            placeholder="E-mail для связи"
          />
        </div>
        <div class="auth__input mb-3 rounded">
          <input
            v-model="userinn"
            v-on:blur="isValidated"
            name="userinn"
            placeholder="ИНН организации"
          />
        </div>
        <div class="auth__input mb-3 rounded">
          <input
            v-model="userkpp"
            v-on:blur="isValidated"
            name="userkpp"
            placeholder="КПП организации"
          />
        </div>

        <div class="auth__input mb-3 rounded">
          <input
            v-model="userlastlogin"
            v-on:blur="isValidated"
            name="userlastlogin"
            placeholder="Дата, когда последний раз удалось войти в систему"
          />
        </div>
        <div class="auth__input mb-3 rounded">
          <input
            v-model="useranswer"
            v-on:blur="isValidated"
            name="useranswer"
            placeholder="Ответ на секретный вопрос"
          />
        </div>
        <div class="auth__input mb-3 rounded">
          <input
            v-model="userphone"
            v-on:blur="isValidated"
            name="userphone"
            placeholder="Телефон для связи"
          />
        </div>
        <div class="auth__input mb-3 rounded">
          <input
            v-model="usercomment"
            name="usercomment"
            v-on:blur="isValidated"
            placeholder="Комментарий сотрудника"
          />
        </div>

        <button
          class="auth__button auth__transition rounded"
          :class="isCanSend ? '' : 'auth__button_disabled'"
          v-on:click="pefrormRestore"
          v-if="!isCompleted"
          :disabled="!isCanSend"
        >
          Отправить заявку
        </button>
      </form>
    </div>

    <!-- <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-on:click="pefrormRestore"
        v-if="!isCompleted"
        :disabled="isInProgress"
        color="primary"
      >
        Отправить заявку
      </v-btn>
    </v-card-actions> -->
  </Wrapper>
</template>

<!-- <template>
  <div>
    <v-card class="mx-auto justify-center" max-width="600">
      <v-img
        height="100"
        :src="require('../assets/0199999.jpg')"
        class="white--text align-center"
        style="background-position: left center"
      >
        <v-card-title class="text-h5">{{ appName }}</v-card-title>
      </v-img>

      <v-card-title class="text-h6 font-weight-regular justify-space-between">
        <span>Восстановление доступа через службу поддержки</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-on:submit="pefrormRestore"
          @keyup.enter.native="pefrormRestore"
          ref="supportform"
        >
          <div v-if="!isCompleted">
            Все поля этой формы не обязательны для заполнения, однако от полноты
            и точности заявки будет зависеть скорость и возможность восстановить
            доступ.
          </div>
          <v-text-field
            label="Логин, для которого необходимо восстановить доступ"
            name="userlogin"
            type="text"
            v-model="userlogin"
            v-if="!isCompleted"
            :rules="[rules.globalValidationPattern]"
          ></v-text-field>

          <v-text-field
            label="E-mail, с которым была произведена регистрация в системе"
            name="useremail"
            type="text"
            v-model="useremail"
            v-if="!isCompleted"
            :rules="[rules.globalValidationPattern]"
          ></v-text-field>

          <v-text-field
            label="E-mail, к которому Вы имеете доступ сейчас"
            name="useremailactual"
            type="text"
            v-model="useremailactual"
            v-if="!isCompleted"
            :rules="[rules.globalValidationPattern]"
          ></v-text-field>

          <v-text-field
            label="ИНН организации"
            name="userinn"
            type="text"
            v-model="userinn"
            v-if="!isCompleted"
            :rules="[rules.globalValidationPattern]"
          ></v-text-field>

          <v-text-field
            label="КПП организации"
            name="userkpp"
            type="text"
            v-model="userkpp"
            v-if="!isCompleted"
            :rules="[rules.globalValidationPattern]"
          ></v-text-field>

          <v-text-field
            label="Дата, когда последний раз удалось войти в систему"
            name="userlastlogin"
            type="text"
            v-model="userlastlogin"
            v-if="!isCompleted"
            :rules="[rules.globalValidationPattern]"
          ></v-text-field>

          <v-text-field
            label="Ответ на секретный вопрос (возможный)"
            name="useranswer"
            type="text"
            v-model="useranswer"
            v-if="!isCompleted"
            :rules="[rules.globalValidationPattern]"
          ></v-text-field>

          <v-text-field
            label="Телефон для связи"
            name="userphone"
            type="text"
            v-model="userphone"
            v-if="!isCompleted"
            :rules="[rules.globalValidationPattern]"
          ></v-text-field>

          <v-text-field
            label="Комментарий для сотрудника службы поддержки"
            name="usercomment"
            type="text"
            v-model="usercomment"
            v-if="!isCompleted"
            :rules="[rules.globalValidationPattern]"
          ></v-text-field>

          <v-alert outlined type="success" text v-if="isCompleted">
            Ваша заявка успешно отправлена в службу поддержки. Мы свяжемся с
            Вами в процессе рассмотрения заявки.
          </v-alert>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-on:click="pefrormRestore"
          v-if="!isCompleted"
          :disabled="isInProgress"
          color="primary"
        >
          Отправить заявку
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template> -->

<script>
import { endpointUrl } from "../../app.config";
import axios from "axios";
import Wrapper from "./Wrapper.vue";

export default {
  props: {
    user: Object,
    appName: String,
  },
  components: { Wrapper },
  name: "Support",
  data: () => {
    return {
      userlogin: "",
      useremail: "",
      useremailactual: "",
      userinn: "",
      userkpp: "",
      userlastlogin: "",
      useranswer: "",
      userphone: "",
      usercomment: "",
      isCompleted: false,
      isInProgress: false,
      isCanSend: false,
      activeHover: null,
      rules: {
        globalValidationPattern: (value) =>
          new RegExp(/^[\p{L}0-9 @.]+$/iu).test(value) ||
          !value ||
          "Заполнено некорректно",
      },
    };
  },

  methods: {
    goToLogin(e) {
      if (e) e.preventDefault();
      this.$router.push("/login");
    },
    setActiveHover(field) {
      if (this.activeHover === field) {
        this.activeHover = null;
      } else {
        this.activeHover = field;
      }
    },
    pefrormRestore(e) {
      if (e) e.preventDefault();
      let self = this;

      if (this.isValidated()) {
        // if (!self.isInProgress && self.$refs.supportform.validate()) {
        self.isInProgress = true;
        axios({
          url: endpointUrl + "restoreBySupport",
          method: "POST",
          data: {
            userData: {
              userlogin: self.userlogin,
              useremail: self.useremail,
              useremailactual: self.useremailactual,
              userinn: self.userinn,
              userkpp: self.userkpp,
              userlastlogin: self.userlastlogin,
              useranswer: self.useranswer,
              userphone: self.userphone,
              usercomment: self.usercomment,
            },
          },
        }).then((res) => {
          self.isInProgress = false;
          if (res && res.data && res.data.status === "ok") {
            self.isCompleted = true;
          } else {
            alert("Возникла ошибка при отправке заявки");
          }
        });
      }
    },
    isValidated: function () {
      if (
        this.userlogin !== "" &&
        this.useremail !== "" &&
        this.useremailactual !== "" &&
        this.userinn !== "" &&
        this.userkpp !== "" &&
        this.userlastlogin !== "" &&
        this.useranswer !== "" &&
        this.userphone !== "" &&
        this.usercomment !== ""
      ) {
        this.isCanSend = true;
        return true;
      }
      this.isCanSend = false;
      return false;
    },
  },
};
</script>
