<template>
  <div class="home">
    <Auth :appName="appName" />
  </div>
</template>

<script>
// @ is an alias to /src
import Auth from '@/components/Auth.vue'

export default {
  props:['appName'],
  name: 'AuthView',
  components: {
    Auth
  }
}
</script>
