<template>
  <div class="home">
    <Restore :appName="appName" />
  </div>
</template>

<script>
// @ is an alias to /src
import Restore from '@/components/Restore.vue'

export default {
  props:['appName'],
  name: 'RestoreView',
  components: {
    Restore
  }
}
</script>
