<template>
  <div
    class="loader__container auth__transition d-flex align-center justify-center"
    :class="[isLoading ? '' : 'loader__hidden']"
  >
    <v-progress-circular
      :size="60"
      indeterminate
      color="#1F837A"
    ></v-progress-circular>
  </div>
</template>

<script>
import "../assets/styles/loader.css";

export default {
  props: {
    isLoading: Boolean,
  },
  data() {
    return {
      start: this.isLoading ? new Date().getTime() : null,
    };
  },
  // watch: {
  //   isLoading: (cur, prev) => {
  //     // console.log("isActive", cur, prev);
  //   },
  // },
};
</script>
