<template>
  <div>
    <v-card class="mx-auto justify-center" max-width="500">
      <v-img
        height="100"
        :src="require('../assets/0199999.jpg')"
        class="white--text align-center"
        style="background-position: left center"
      >
        <v-card-title class="text-h5">{{ appName }}</v-card-title>
      </v-img>

      <v-card-title class="text-h6 font-weight-regular justify-space-between">
        <span>Информация о пользователе</span>
      </v-card-title>

      <v-card-text v-show="gotdata">
        <v-form>
          <v-text-field
            label="Username"
            name="username"
            type="text"
            v-model="username"
            :disabled="true"
          ></v-text-field>
          <v-text-field
            label="Email"
            name="email"
            type="text"
            v-model="email"
            :disabled="true"
          ></v-text-field>
          <v-text-field
            label="Секретный вопрос"
            name="secretq"
            type="text"
            v-model="secretq"
            :disabled="true"
          ></v-text-field>

          <v-text-field
            label="Ответ на секретный вопрос"
            name="secreta"
            v-model="secreta"
            :disabled="false"
            :type="show1 ? 'text' : 'password'"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-btn
            color="secondary"
            v-on:click="restore"
            :disabled="passIsRestored || restoreRequested"
            x-small
          >
            Выслать пользователю временный пароль
          </v-btn>
          <br /><br />
          <v-alert
            icon="mdi-shield-lock-outline"
            prominent
            text
            type="info"
            v-show="passIsRestored"
          >
            Пользователю на почту успешно выслан временный пароль
          </v-alert>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { endpointUrl } from "../../app.config";
import axios from "axios";

export default {
  props: {
    user: Object,
    appName: String,
  },
  name: "UserInfo",
  data: () => ({
    uid: "",
    username: "",
    email: "",
    secretq: "",
    secreta: "",
    passIsRestored: false,
    restoreRequested: false,
    show1: false,
    gotdata: false,
    adminjwt: "",
  }),

  created() {
    let self = this;
    const getJwt = (name) =>
      document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
      "";
    self.adminjwt = getJwt("authtoken");
    axios({
      url: endpointUrl + "userinfo",
      method: "POST",
      data: {
        userId: this.$route.query.userId,
        adminjwt: self.adminjwt,
      },
    }).then((res) => {
      if (res && res.data && res.data.status === "ok") {
        self.gotdata = true;
        self.uid = res.data.userdata.id;
        self.username = res.data.userdata.username;
        self.email = res.data.userdata.email;
        self.secretq = res.data.userdata.secretq;
        self.secreta = res.data.userdata.secreta;
      }
    });
  },

  methods: {
    restore(e) {
      if (e) e.preventDefault();
      let self = this;
      if (!self.passIsRestored && !self.restoreRequested && self.email) {
        self.restoreRequested = true;
        axios({
          url: endpointUrl + "userinfo",
          method: "POST",
          data: {
            userId: this.$route.query.userId,
            restoreRequested: true,
            adminjwt: self.adminjwt,
          },
        }).then((res) => {
          if (res && res.data && res.data.status === "password changed") {
            self.passIsRestored = true;
          }
        });
      }
    },
  },
};
</script>