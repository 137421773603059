<template>
  <div class="home">
    <UserInfo :appName="appName" />
  </div>
</template>

<script>
// @ is an alias to /src
import UserInfo from '@/components/UserInfo.vue'

export default {
  props:['appName'],
  name: 'UserInfoView',
  components: {
    UserInfo
  }
}
</script>
