<template>
  <div class="auth__input mb-5 rounded" :class="[styles ? styles : '']">
    <input
      v-model="localModel"
      :name="value"
      :placeholder="placeholder"
      :disabled="disabledCondition ? disabledCondition : false"
    />
  </div>
</template>

<script>
export default {
  props: ["value", "placeholder", "disabledCondition", "styles", "rules"],
};
</script>
