<template>
  <div class="auth__banner">
    <!-- put the banner image here -->
    <div class="auth__banner_gradient"></div>
    <img
      class="auth__banner_default_slide auth__lazy_transition"
      :class="[activeSlide === slide ? 'auth__banner_active_slide' : '']"
      v-for="slide in slides"
      :src="require('../' + slide)"
      v-bind:key="slide"
      :alt="slide"
    />
  </div>
</template>

<script>
import { STATIC_TO_SLIDE } from "../plugins/slider.js";
import "../assets/styles/main.css";

export default {
  data() {
    return {
      slides: [],
      activeSlide: null,
    };
  },
  beforeMount() {
    this.slides =
      STATIC_TO_SLIDE && STATIC_TO_SLIDE.length ? [...STATIC_TO_SLIDE] : [];
    this.activeSlide = this.slides.length && this.slides[0];

    setInterval(() => {
      let currentIndex = this.slides.findIndex(
        (item) => item === this.activeSlide
      );
      if (!this.slides[currentIndex + 1]) {
        this.activeSlide = this.slides[0];
      } else {
        this.activeSlide = this.slides[currentIndex + 1];
      }
    }, 10000);
  },
};
</script>
