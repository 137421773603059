<template>
  <div class="home">
    <Register :appName="appName" />
  </div>
</template>

<script>
// @ is an alias to /src
import Register from '@/components/Register.vue'

export default {
  props:['appName'],
  name: 'RegisterView',
  components: {
    Register
  }
}
</script>
