var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Wrapper',{attrs:{"appName":_vm.appName,"title":'Регистрация'}},[_c('div',{staticClass:"anim__balls mb-5"},[_c('div',{staticClass:"anim__ball",class:_vm.e1 >= 1 ? 'anim__ball_completed' : ''}),_c('div',{staticClass:"anim__ball",class:_vm.e1 >= 2 ? 'anim__ball_completed' : ''}),_c('div',{staticClass:"anim__ball",class:_vm.e1 >= 3 ? 'anim__ball_completed' : ''}),_c('div',{staticClass:"anim__ball",class:_vm.isCompleted ? 'anim__ball_completed' : ''})]),_c('div',{staticClass:"auth__transition"},[(_vm.e1 === 1)?_c('div',[_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          !_vm.fio_f && _vm.firstBlured.find(function (item) { return item === 'fio_f'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(!_vm.fio_f && _vm.firstBlured.find(function (item) { return item === 'fio_f'; }))?_c('label',{staticClass:"auth__error_text"},[_vm._v("Укажите фамилию")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fio_f),expression:"fio_f"}],attrs:{"name":"fio_f","placeholder":"Введите фамилию"},domProps:{"value":(_vm.fio_f)},on:{"change":function($event){return _vm.setBlured('fio_f')},"input":function($event){if($event.target.composing){ return; }_vm.fio_f=$event.target.value}}})]),_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          !_vm.fio_i && _vm.firstBlured.find(function (item) { return item === 'fio_i'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(!_vm.fio_i && _vm.firstBlured.find(function (item) { return item === 'fio_i'; }))?_c('label',{staticClass:"auth__error_text"},[_vm._v("Укажите имя")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fio_i),expression:"fio_i"}],attrs:{"name":"fio_i","placeholder":"Введите имя"},domProps:{"value":(_vm.fio_i)},on:{"change":function($event){return _vm.setBlured('fio_i')},"input":function($event){if($event.target.composing){ return; }_vm.fio_i=$event.target.value}}})]),_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          !_vm.fio_o && _vm.firstBlured.find(function (item) { return item === 'fio_o'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(!_vm.fio_o && _vm.firstBlured.find(function (item) { return item === 'fio_o'; }))?_c('label',{staticClass:"auth__error_text"},[_vm._v("Укажите отчество")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fio_o),expression:"fio_o"}],attrs:{"name":"fio_o","placeholder":"Введите отчество"},domProps:{"value":(_vm.fio_o)},on:{"change":function($event){return _vm.setBlured('fio_o')},"input":function($event){if($event.target.composing){ return; }_vm.fio_o=$event.target.value}}})]),_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          (!_vm.email || !_vm.EMAIL_REGEXP.test(_vm.email)) &&
          _vm.firstBlured.find(function (item) { return item === 'email'; })
            ? 'auth__error_border mb-10'
            : 'mb-5' ]},[(
            (!_vm.email || !_vm.EMAIL_REGEXP.test(_vm.email)) &&
            _vm.firstBlured.find(function (item) { return item === 'email'; })
          )?_c('label',{staticClass:"auth__error_text auth_error_email"},[_vm._v(_vm._s(!_vm.email ? "Укажите электронную почту" : "Укажите корректный адрес почты")+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"name":"email","placeholder":"Введите эл. почту"},domProps:{"value":(_vm.email)},on:{"change":function($event){return _vm.setBlured('email')},"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]),_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          _vm.validatePassword(_vm.password) &&
          _vm.firstBlured.find(function (item) { return item === 'password'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(
            _vm.validatePassword(_vm.password) &&
            _vm.firstBlured.find(function (item) { return item === 'password'; })
          )?_c('label',{staticClass:"auth__error_text"},[_vm._v(_vm._s(_vm.validatePassword(_vm.password)))]):_vm._e(),_c('div',{staticClass:"auth__two_params"},[(!_vm.show1)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"name":"password","placeholder":"Пароль","type":"password"},domProps:{"value":(_vm.password)},on:{"change":function($event){return _vm.setBlured('password')},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"name":"password","placeholder":"Пароль","type":"text"},domProps:{"value":(_vm.password)},on:{"change":function($event){return _vm.setBlured('password')},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('button',{on:{"click":_vm.switchVisibleIcon}},[_c('v-icon',[_vm._v(_vm._s(_vm.show1 ? "mdi-eye" : "mdi-eye-off"))])],1)])]),_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          (!_vm.password2 || _vm.rules.passequal(_vm.password2).length > 0) &&
          _vm.firstBlured.find(function (item) { return item === 'password2'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(
            (!_vm.password2 || _vm.rules.passequal(_vm.password2).length > 0) &&
            _vm.firstBlured.find(function (item) { return item === 'password2'; })
          )?_c('label',{staticClass:"auth__error_text"},[_vm._v("Укажите повторно пароль")]):_vm._e(),_c('div',{staticClass:"auth__two_params"},[(!_vm.show2)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password2),expression:"password2"}],attrs:{"name":"password2","placeholder":"Пароль","type":"password"},domProps:{"value":(_vm.password2)},on:{"change":function($event){return _vm.setBlured('password2')},"input":function($event){if($event.target.composing){ return; }_vm.password2=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password2),expression:"password2"}],attrs:{"name":"password2","placeholder":"Пароль","type":"text"},domProps:{"value":(_vm.password2)},on:{"change":function($event){return _vm.setBlured('password2')},"input":function($event){if($event.target.composing){ return; }_vm.password2=$event.target.value}}}),_c('button',{on:{"click":_vm.switchVisibleSecondIcon}},[_c('v-icon',[_vm._v(_vm._s(_vm.show2 ? "mdi-eye" : "mdi-eye-off"))])],1)])]),_c('span',{staticClass:"register__span_desc"},[_vm._v("Пароль должен быть не менее 8 символов, включать заглавные и строчные буквы, цифры, спецсимволы")]),_c('v-btn',{attrs:{"color":"secondary","x-small":""},on:{"click":_vm.generatePassword}},[_vm._v(" Сгенерировать безопасный пароль ")])],1):(_vm.e1 === 2)?_c('div',[_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          !_vm.companyInn && _vm.firstBlured.find(function (item) { return item === 'companyInn'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(
            !_vm.companyInn && _vm.firstBlured.find(function (item) { return item === 'companyInn'; })
          )?_c('label',{staticClass:"auth__error_text"},[_vm._v("Укажите ИНН компании")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInn),expression:"companyInn"}],attrs:{"name":"companyInn","placeholder":"Введите ИНН компании"},domProps:{"value":(_vm.companyInn)},on:{"change":function($event){return _vm.setBlured('companyInn')},"input":function($event){if($event.target.composing){ return; }_vm.companyInn=$event.target.value}}})]),_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          !_vm.companyKpp && _vm.firstBlured.find(function (item) { return item === 'companyKpp'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(
            !_vm.companyKpp && _vm.firstBlured.find(function (item) { return item === 'companyKpp'; })
          )?_c('label',{staticClass:"auth__error_text"},[_vm._v("Укажите КПП компании")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyKpp),expression:"companyKpp"}],attrs:{"name":"companyKpp","placeholder":"Введите КПП компании"},domProps:{"value":(_vm.companyKpp)},on:{"change":function($event){return _vm.setBlured('companyKpp')},"input":function($event){if($event.target.composing){ return; }_vm.companyKpp=$event.target.value}}})]),_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          !_vm.phone && _vm.firstBlured.find(function (item) { return item === 'phone'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(!_vm.phone && _vm.firstBlured.find(function (item) { return item === 'phone'; }))?_c('label',{staticClass:"auth__error_text"},[_vm._v("Укажите номер телефона")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],attrs:{"name":"phone","placeholder":"Введите рабочий номер телефона"},domProps:{"value":(_vm.phone)},on:{"change":function($event){return _vm.setBlured('phone')},"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}})])]):(_vm.e1 === 3)?_c('div',[_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          !_vm.fio_f && _vm.firstBlured.find(function (item) { return item === 'fio_f'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(!_vm.username && _vm.firstBlured.find(function (item) { return item === 'username'; }))?_c('label',{staticClass:"auth__error_text"},[_vm._v("Укажите Логин")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],attrs:{"name":"username","placeholder":"Введите Логин"},domProps:{"value":(_vm.username)},on:{"change":function($event){return _vm.setBlured('username')},"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}})]),_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          !_vm.secretQuestion &&
          _vm.firstBlured.find(function (item) { return item === 'secretQuestion'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(
            !_vm.secretQuestion &&
            _vm.firstBlured.find(function (item) { return item === 'secretQuestion'; })
          )?_c('label',{staticClass:"auth__error_text"},[_vm._v("Укажите Секретный вопрос")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secretQuestion),expression:"secretQuestion"}],attrs:{"name":"secretQuestion","placeholder":"Введите секретный вопрос"},domProps:{"value":(_vm.secretQuestion)},on:{"change":function($event){return _vm.setBlured('secretQuestion')},"input":function($event){if($event.target.composing){ return; }_vm.secretQuestion=$event.target.value}}})]),_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          !_vm.secretAnswer && _vm.firstBlured.find(function (item) { return item === 'secretAnswer'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(
            !_vm.secretAnswer &&
            _vm.firstBlured.find(function (item) { return item === 'secretAnswer'; })
          )?_c('label',{staticClass:"auth__error_text"},[_vm._v("Укажите ответ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secretAnswer),expression:"secretAnswer"}],attrs:{"name":"secretAnswer","placeholder":"Введите ответ на секретный вопрос"},domProps:{"value":(_vm.secretAnswer)},on:{"change":function($event){return _vm.setBlured('secretAnswer')},"input":function($event){if($event.target.composing){ return; }_vm.secretAnswer=$event.target.value}}})])]):_c('div',[(_vm.isCompleted)?_c('v-alert',{attrs:{"outlined":"","type":"success","text":""}},[_vm._v(" Регистрация прошла успешно. Сейчас мы проверяем данные и предоставляем права доступа. Напишем, когда всё будет готово. ")]):_vm._e(),(_vm.validationCodeError)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Не удалось завершить регистрацию. Возможно, был введен неверный код подтверждения. ")]):_vm._e(),_c('div',{staticClass:"auth__input rounded auth__transition",class:[
          !_vm.confirmCode && _vm.firstBlured.find(function (item) { return item === 'confirmCode'; })
            ? 'auth__error_border mb-8'
            : 'mb-5' ]},[(
            !_vm.confirmCode && _vm.firstBlured.find(function (item) { return item === 'confirmCode'; })
          )?_c('label',{staticClass:"auth__error_text"},[_vm._v("Укажите код подтверждения")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmCode),expression:"confirmCode"}],attrs:{"name":"confirmCode","placeholder":"Введите код подтверждения"},domProps:{"value":(_vm.confirmCode)},on:{"change":function($event){return _vm.setBlured('confirmCode')},"input":function($event){if($event.target.composing){ return; }_vm.confirmCode=$event.target.value}}})])],1)]),_c('button',{staticClass:"mt-10 auth__button rounded auth__transition",class:[!_vm.isWaiting ? '' : 'auth__button_disabled'],attrs:{"disabled":_vm.isWaiting},on:{"click":function($event){_vm.isCompleted ? _vm.goToLogin() : _vm.nextStep()}}},[_vm._v(" "+_vm._s(_vm.isCompleted ? "Завершить" : "Далее")+" ")]),_c('button',{staticClass:"mt-3 auth__button register__back_button rounded auth__transition",on:{"click":_vm.goToLogin}},[_vm._v(" Вернуться ко входу ")])])}
var staticRenderFns = []

export { render, staticRenderFns }